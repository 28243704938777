import React from "react";
import BaseLayout from "../components/baseLayout";
import { graphql } from "gatsby";
import HeroTitle from "../components/heroTitle";
import { BlogSectionContainer } from "../components/smallsComponents";

export default function WorkWithUsItem({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <BaseLayout>
      <HeroTitle title={frontmatter.title} />
      <div className="container">
        <BlogSectionContainer>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </BlogSectionContainer>
      </div>
    </BaseLayout>
  );
}

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;

import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export function TagTool({ type, toolName }) {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//content/processesAndTools//" } }
      ) {
        edges {
          node {
            frontmatter {
              name
              type
            }
          }
        }
      }
    }
  `);
  if (!!type && !toolName) {
    switch (type) {
      case "CORE_TECH":
        return <Tag type={type} toolName={"Core Tech"} />;
      case "CUSTOM_TECH":
        return <Tag type={type} toolName={"Custom Tech"} />;
      default:
        return <Tag type={type} toolName={"Proven Process"} />;
    }
  }
  const tool = data.allMarkdownRemark.edges.find(
    (x) => x.node.frontmatter.name === toolName
  );
  return <Tag type={tool.node.frontmatter.type} toolName={toolName} />;
}

function Tag({ type, toolName }) {
  switch (type) {
    case "CORE_TECH":
      return <TagCoreTech toolName={toolName} />;
    case "CUSTOM_TECH":
      return <TagCustomTech toolName={toolName} />;
    default:
      return <TagProvenProcess toolName={toolName} />;
  }
}

function TagCoreTech({ toolName }) {
  return (
    <span className="tag is-info is-medium has-text-weight-semibold">
      {toolName}
    </span>
  );
}

function TagCustomTech({ toolName }) {
  return (
    <span className="tag is-warning is-medium has-text-weight-semibold">
      {toolName}
    </span>
  );
}

function TagProvenProcess({ toolName }) {
  return (
    <span className="tag is-success is-medium has-text-weight-semibold">
      {toolName}
    </span>
  );
}

export function BlogSectionContainer(props) {
  return (
    <div className="section">
      <div className="columns">
        <div className="column is-9-widescreen content">{props.children}</div>
      </div>
    </div>
  );
}

import React from "react";

export default function HeroTitle({ title, subtitle, description }) {
  return (
    <section className="hero is-primary">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">{title}</h1>
          <h2 className="subtitle">{subtitle}</h2>
          <div className="description">{description}</div>
        </div>
      </div>
    </section>
  );
}
